import React from "react";
import { graphql } from "gatsby";
import { DOMParser } from "xmldom";
import { parseDOM } from "htmlparser2";
import { filter } from "domutils";
import render from "dom-serializer";
import themeCss from "../styles/rst/theme";
import styled from 'styled-components';
import { Container } from "@chakra-ui/react";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import Header from "../components/sections/Header";
import { ChakraProvider, CSSReset} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import Footer from "../components/sections/Footer"


const StyledDiv = styled.div`
${themeCss}
`
function getImagePath(src, allFile) {
  const index = src.lastIndexOf('/');
  const name = src.slice(index + 1).split(".")[0];
  const node = allFile["edges"].find(function(node) {
    return node.node.name === name
  })
  return node.node.publicURL;
}

function transformBody(body, allFile) {
  const dom = parseDOM(body)
  const images = filter(function(elem) {
    return elem.type == "tag" && elem.name == "img";
  }, dom)
  images.forEach(function (img) {
    const path = getImagePath(img.attribs.src, allFile);
    img.attribs.src = path;
  })
  return render(dom)
}

function makeCardMetaTag(name, element) {
  const content = element.getAttribute('content')
  if (name == 'image') {
    return <meta property="og:image" content={'https://buildingblock.ai/images/' + content}/>
  }
  return <meta property={'og:' + name} content={content}/>
}

function makeMetaTag(element) {
  const name = element.getAttribute('name')
  const cardPrefix = 'card.'
  if (name.startsWith(cardPrefix)) {
    return makeCardMetaTag(name.substring(cardPrefix.length), element)
  }
  return <meta name={element.getAttribute('name')} content={element.getAttribute('content')}/>
}

const Metadata = (props) => {
  const parser = new DOMParser()
  const metaElements = (function() {
    if (!props.metatags) {
      return [];
    }
    const doc = parser.parseFromString(props.metatags, "text/xml")
    if (doc == null) {
      return [];
    }
    return Array.prototype.slice.call(doc.getElementsByTagName("meta"))

  })();
  let metas = metaElements.map((element) => 
    makeMetaTag(element)
  );
  metas.push(
    <meta name="twitter:card" content="summary_large_image"/>
  )
  metas.push(
    <meta name="og:url" content={'https://buildingblock.ai/' + props.pageName}/>
  )
  return (
    <Helmet  title={props.title}>
      {metas}
    </Helmet>
  )
};

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { rstPagesJson, allFile } = data // data.markdownRemark holds your post data
  const { current_page_name, title, metatags, body } = rstPagesJson
  const body_p = transformBody(body, allFile)
  return (
    <main>
      <Metadata title={title} metatags={metatags} pageName={current_page_name}/>
      <ChakraProvider>
      <Flex 
          direction="column"
          maxW={{ xl: "1200px" }}
          m="0 auto"
      >
        <Header />
        <Flex bg="white" h="100%">
          <Spacer />
          <Box maxW="700px" mx="auto" h="100%">
            <StyledDiv width="100%">
                <div className="wy-nav-content" width="100%">
                  <div className="rst-content" width="100%" dangerouslySetInnerHTML={{ __html: body_p }}/>
              </div>
            </StyledDiv>
          </Box>
          <Spacer />
        </Flex>
        <Footer />
      </Flex>
      </ChakraProvider>
    </main>
  )
}

export const pageQuery = graphql`
  query($current_page_name: String!) {
    rstPagesJson(current_page_name: { eq: $current_page_name }) {
      current_page_name
      title
      metatags
      body
    },
    allFile(filter: {relativeDirectory: {eq: "_images"}}) {
      edges  {
        node {
          id
          name
          publicURL
        }
      }
    }
  }
`
