import React, { useState } from "react";
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  chakra,
  Container,
  FormControl,
  Link,
  SimpleGrid,
  Stack,
  Text,
  VisuallyHidden,
  Input,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { BiMailSend } from 'react-icons/bi';

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
      {children}
    </Text>
  );
};

export function ErrorMessage({ message }) {
  return (
    <Box my={4}>
      <Alert status="error" borderRadius={4}>
        <AlertIcon />
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    </Box>
  );
}

export default function LargeWithNewsletter() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const handleSubmit = async event => {
    event.preventDefault();
    const result = await addToMailchimp(email)
    if (result.result != 'success') {
      setError(result.msg);
    } else {
      setEmail('');
      setError('');
      setStatus('done');
    }
  };
  return (
    <Box
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
      <form onSubmit={handleSubmit}>
      <FormControl id={"email"}>
      <Stack direction={'row'} align={'center'}>
        <ListHeader>Stay up to date</ListHeader>
          <Input
            type={'email'}
            placeholder={'Your email address'}
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            onChange={event => setEmail(event.currentTarget.value)}
            border={0}
            _focus={{
              bg: 'whiteAlpha.300',
            }}
          />
          <IconButton
            type={'submit'}
            bg={useColorModeValue('green.400', 'green.800')}
            color={useColorModeValue('white', 'gray.800')}
            _hover={{
              bg: 'green.600',
            }}
            aria-label="Subscribe"
            icon={<BiMailSend />}
          />
      </Stack>
      </FormControl>
      {error && <ErrorMessage message={error}/>}
      {status && <Text>Thank you for Subscribing!</Text>}
      </form>
      </Container>
    </Box>
  );
}
