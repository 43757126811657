import pygmentsCss from './pygments.js'
import defaultCss from './defaults.js'
import katexMathCss from './katexMath.js'
import pseudoCss from './pseudo.js'

const css = `
${defaultCss}
${pygmentsCss}
${katexMathCss}
${pseudoCss}
`

export default css
